.button {
    background-color: transparent;
    border: none;
    color: white;
    /* outline: none; */
    cursor: pointer;
    font: inherit;
    padding: 10px;
    margin: 10px;
    font-weight: bold;
}

.button:disabled {
    color: #ccc;
    cursor: not-allowed;
}

.button:first-of-type {
    margin-left: 0;
    padding-left: 0;
}

.success {
    color: #5C9210;
}

.alert {
    color: #944317;
}