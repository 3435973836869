.navigationItem {
    margin: 10px 0;
    box-sizing: border-box;
    display: block;
    width: 100%;
}

.navigationItem a {
    color: #8f5c2c;
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    display: block;
}

.navigationItem a:hover, .navigationItem a:active, .navigationItem a.active {
    color: #40a4c8;
}

@media (min-width: 500px) {
    .navigationItem {
        margin: 0;
        display: flex;
        height: 100%;
        width: auto;
        align-items: center;
    }
    .navigationItem a {
        color: white;
        height: 100%;
        padding: 16px 10px;
        border-bottom: 4px solid transparent;
    }
    .navigationItem a:hover, .navigationItem a:active, .navigationItem a.active {
        background-color: #8f5c2c;
        border-bottom: 4px solid #40a4c8;
        color: white;
    }
}